import { format } from 'date-fns';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import {
  languageAtom,
  privacyConsentInfoAtom,
  privacyConsentModalInfoAtom,
} from 'store/globalStateAtom';

import checkBoxTrue from 'images/PassportCertification/CheckBoxTrue.png';
import checkBoxFalse from 'images/PassportCertification/CheckBoxFalse.png';
import { LANGUAGE } from 'types/language.type';

function PrivacyConsent() {
  const { t } = useTranslation();
  const [lang] = useAtom(languageAtom);

  const [privacyConsentInfo, setPrivacyConsentInfo] = useAtom(
    privacyConsentInfoAtom,
  );
  const setPrivacyConsentModalInfo = useSetAtom(privacyConsentModalInfoAtom);

  const handlePrivacyConsent = () => {
    setPrivacyConsentInfo((prev) => {
      const newIsPrivacyConsent = !prev.isPrivacyConsent;
      const privacyAgreedTime = newIsPrivacyConsent
        ? format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
        : '';
      console.log('privacyAgreedTime', newIsPrivacyConsent, privacyAgreedTime);

      return {
        isPrivacyConsent: newIsPrivacyConsent,
        privacyAgreedTime,
      };
    });
  };

  const onClickSeeDetail = () => {
    setPrivacyConsentModalInfo({
      title: t('privacy_consent_title'),
      description: t('privacy_consent_content'),
      btnText: t('privacy_consent_modal_close'),
      btnCallback: () => {
        setPrivacyConsentModalInfo(null);
      },
    });
  };

  return (
    <div className='flex mt-[38px] gap-6'>
      <div className='flex items-center gap-6'>
        <input
          id='privacy_consent'
          className='hidden'
          type='checkbox'
          checked={privacyConsentInfo?.isPrivacyConsent}
          onChange={handlePrivacyConsent}
        />
        <img
          src={
            privacyConsentInfo?.isPrivacyConsent ? checkBoxTrue : checkBoxFalse
          }
          onClick={handlePrivacyConsent}
          alt={
            privacyConsentInfo?.isPrivacyConsent
              ? 'privacyConsentTrue'
              : 'privacyConsentFalse'
          }
        />
        <label
          htmlFor='privacy_consent'
          className={`font-semibold text-[${
            [LANGUAGE.EN, LANGUAGE.KO].includes(lang) ? '30px' : '36px'
          }] w-[722px] text-[#949494] whitespace-pre-wrap`}
        >
          {t('privacy_consent')}
        </label>
      </div>
      <button
        className='font-medium text-[30px] text-[#949494] underline min-w-[142px] whitespace-nowrap'
        onClick={onClickSeeDetail}
      >
        {t('privacy_consent_see_detail')}
      </button>
    </div>
  );
}

export default PrivacyConsent;
