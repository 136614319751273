import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import useUpdateKioskStatus from 'hooks/useUpdateKioskStatus';
import useLogging from 'hooks/useLogging';
import connectPort from 'images/common/connect_port_guide.png';
import {
  adminLoginInfoAtom,
  billTypeAtom,
  CountInfo,
  kioskStatusAtom,
  loginInfoAtom,
  modalInfoAtom,
  moneyNotWithdrawnAtom,
  privacyConsentInfoAtom,
  refundInfoAtom,
} from 'store/globalStateAtom';
import {
  calculateBC,
  calculateFCC,
  convertErrorCode,
  getIsKioskError,
  getIsLackOfCash,
} from 'utils';
import useCompleteRefunds from 'hooks/useCompleteRefunds';

function useSerialCommunication() {
  const location = useLocation();

  const { t } = useTranslation();
  const STX = 0xfe;
  const { mutate } = useUpdateKioskStatus();
  const { isLoading: refundCompleteLoading, mutate: completeRefunds } =
    useCompleteRefunds();
  const { mutate: logOnServer } = useLogging();
  const navigate = useNavigate();
  const timerRef = useRef<any>(null);

  const [moneyNotWithdrawn, setMoneyNotWithdrawn] = useAtom(
    moneyNotWithdrawnAtom,
  );
  const [port, setPort] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [kioskStatus, setKioskStatus] = useAtom(kioskStatusAtom);
  const [, setAdminLoginInfo] = useAtom(adminLoginInfoAtom);
  const [refundInfo] = useAtom(refundInfoAtom);
  const [loginInfo] = useAtom(loginInfoAtom);
  const [billType] = useAtom(billTypeAtom);
  const [privacyConsentInfo, setPrivacyConsentInfo] = useAtom(
    privacyConsentInfoAtom,
  );

  // * 아래 useRef 사용 이유 *
  //- processValue 함수가 내부 함수여서 외부 상태들의 최신화된 값을 가져오지 못함
  //- 내부함수에서는 ref를 통해서만 최신값을 가져올 수 있어서 useRef 사용
  const currentMoneyNotWithdrawnRef = useRef(moneyNotWithdrawn);
  const currentKioskStatusRef = useRef(kioskStatus);
  const currentLoginInfoRef = useRef(loginInfo);
  const resetDefaultInquiryCountRef = useRef(0);
  const emitDefaultInquiryCountRef = useRef(0);
  const refundInfoRef = useRef(refundInfo);
  const currentCMDRef = useRef<'RESET' | 'EMIT' | null>();
  const currentLocationRef = useRef<string>('');

  const withdrawalTargetCountRef = useRef({
    bd1Count: 0,
    bd2Count: 0,
    bd3Count: 0,
    hp1Count: 0,
  });
  const privacyConsentInfoRef = useRef(privacyConsentInfo);

  const log = (data: string) => {
    logOnServer({ controlCode: loginInfo?.controlCode, data });
  };

  useEffect(() => {
    privacyConsentInfoRef.current = privacyConsentInfo;
  }, [privacyConsentInfo]);

  const goRefundComplete = () => {
    completeRefunds({
      controlCode: currentLoginInfoRef.current.controlCode,
      ...refundInfoRef.current,
      privacyAgreedTime: privacyConsentInfoRef.current?.privacyAgreedTime,
    });
    setPrivacyConsentInfo({ isPrivacyConsent: false, privacyAgreedTime: '' });
  };
  useEffect(() => {
    currentKioskStatusRef.current = kioskStatus;
  }, [kioskStatus]);

  useEffect(() => {
    currentLoginInfoRef.current = loginInfo;
  }, [loginInfo]);

  useEffect(() => {
    currentMoneyNotWithdrawnRef.current = moneyNotWithdrawn;
  }, [moneyNotWithdrawn]);

  useEffect(() => {
    refundInfoRef.current = refundInfo;
  }, [refundInfo]);

  useEffect(() => {
    currentLocationRef.current = location.pathname;
  }, [location.pathname]);

  const openInitializationModal = () => {
    const isFirstSerialConnection = getIsFistSerialConnection();
    setModalInfo({
      title: '키오스크 기기 연결을 허용해주세요.',
      pointColorText: isFirstSerialConnection
        ? undefined
        : 'スタッフでお問い合わせください。请咨询职员。Please ask for assistance. ',
      description: `${
        isFirstSerialConnection
          ? '키오스크를 사용하기 위해 연결이 필요합니다.'
          : '페이지 새로고침이 감지되어 키오스크 연결이 끊어졌습니다.'
      }\n아래 사진을 참고해 연결을 진행해주세요.`,
      btnText: '확인',
      icon: 'CHECK',
      imageUrl: connectPort,
      btnCallback: initialize,
    });
  };
  const getIsFistSerialConnection = () => {
    const isFirstSerialConnection = localStorage.getItem(
      'isFirstSerialConnection',
    );
    if (!isFirstSerialConnection) {
      localStorage.setItem('isFirstSerialConnection', 'NO');
      return true;
    } else {
      return false;
    }
  };
  const initialize = async () => {
    try {
      //@ts-ignore
      const port = await navigator.serial.requestPort();
      log('포트 연결 성공');
      setPort(port);
      await port.open({ baudRate: 9600 });
      log('포트 열기 성공');
    } catch (err) {
      setModalInfo({
        title: '웹 사이트 연결을 확인해주세요.',
        description: `이미 키오스크 연결이 완료된 페이지가 실행 중입니다.\n현재 페이지를 닫고 이전에 실행 중인 페이지를 사용해주세요.`,
        btnText: '닫기',
        preventDefault: true,
        btnCallback: openInitializationModal,
        icon: 'ALERT',
      });
      log('포트 열기 실패');
    }
  };

  /** ** 명령어 전송 ** */
  async function sendCommandToSerialPort(commandBuffer: Uint8Array) {
    log(`요청 전송: ${commandBuffer}`);

    //@ts-ignore
    const writer = await port.writable.getWriter();
    await writer.write(commandBuffer);
    await writer.releaseLock();
  }
  /** ** 현금 방출 ** */
  async function emitBills(countInfo: CountInfo) {
    timerRef.current = setTimeout(() => {
      setIsLoading(false);
      setModalInfo({
        title: '기기 연결 번호를 확인해주세요.',
        pointColorText:
          'スタッフでお問い合わせください。请咨询职员。Please ask for assistance. ',
        description: `키오스크 사용을 위해 [COM3]에 연결해주세요.\n아래 사진을 참고해주세요.`,
        btnText: '확인',
        icon: 'CHECK',
        imageUrl: connectPort,
        btnCallback: initialize,
      });
    }, 10000);
    setIsLoading(true);
    const { bd1ErrorCode, bd2ErrorCode, bd3ErrorCode, hp1Error } =
      currentKioskStatusRef.current;

    //@ts-ignore
    const isKioskErrorInActiveCashModule = getIsKioskError({
      billType,
      kioskStatus: currentKioskStatusRef.current,
    });
    if (isKioskErrorInActiveCashModule) {
      const isOnSettingPage = location.pathname === '/setting';
      setIsLoading(false);
      setModalInfo({
        title: `에러가 발생했습니다.${
          hp1Error
            ? ''
            : `(${
                convertErrorCode(bd1ErrorCode || bd2ErrorCode || bd3ErrorCode)
                  .code
              })`
        }`,
        description: isOnSettingPage
          ? '에러가 해결되지 않았습니다.'
          : t('error_kiosk_desc'),
        btnText: isOnSettingPage ? '닫기' : '조치하기',
        btnCallback: () => {
          if (isOnSettingPage) {
            return;
          }
          navigate('/setting');
        },
        icon: 'ERROR',
      });
      return;
    }

    currentCMDRef.current = 'EMIT';
    // 패킷 정보
    const CMD = 0x12;
    const BC = calculateBC(CMD);

    //방출 목표 수량 저장
    withdrawalTargetCountRef.current = countInfo;

    const { hp1Count, bd1Count, bd2Count, bd3Count } = countInfo;
    if (getIsLackOfCash({ billType, kioskStatus, countInfo })) {
      setIsLoading(false);
      setModalInfo({
        title: t('error_cash_title'),
        description: t('error_cash_desc'),
        btnText: t('modal_confirm'),
        icon: 'CHECK',
      });
      return;
    }

    // 데이터 필드 설정
    const dataArray = [
      hp1Count,
      0x00,
      bd1Count,
      bd2Count,
      bd3Count,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
    ];

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandPacket = new Uint8Array([...packetExcludingFcc, fcc]);

    // 키오스크 보드로 패킷 전송
    try {
      await sendCommandToSerialPort(commandPacket);
    } catch (err) {
      //응답
      console.error(err);
    }
  }
  /**** 기본 상태 조회 or 에러 조회 ** */
  const fetchKioskStatus = async (type: 'DEFAULT' | 'ERROR' = 'DEFAULT') => {
    const CMD = 0x11;
    const BC = calculateBC(CMD);

    // 데이터 필드 설정
    const dataArray = [
      type === 'DEFAULT' ? 0b00000000 : 0b00001000,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
      0x00,
    ];

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandBuffer = new Uint8Array([...packetExcludingFcc, fcc]);
    // 명령어 전송
    try {
      await sendCommandToSerialPort(commandBuffer);
    } catch (err) {
      //응답
      console.error(err);
    }
  };
  /**** BD1, BD2, BD3, HP1 리셋 ** */
  const resetKiosk = async () => {
    setIsLoading(true);
    currentCMDRef.current = 'RESET';
    // 패킷 정보
    const CMD = 0x10;
    const BC = calculateBC(CMD);

    // 데이터 필드 설정
    const dataArray = [
      0x00, 0b00001111, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00,
    ];

    // 프레임 체크 코드 계산
    const packetExcludingFcc = [STX, BC, CMD, ...dataArray];
    const fcc = calculateFCC(packetExcludingFcc);

    // 명령어 패킷 생성
    const commandBuffer = new Uint8Array([...packetExcludingFcc, fcc]);
    // 명령어 전송
    try {
      sendCommandToSerialPort(commandBuffer);
    } catch (err) {
      //응답
      setIsLoading(false);
      console.error(err);
    }
  };
  /** ** 응답 수신 ** */
  const readResponse = async () => {
    if (!port) return;
    //@ts-ignore
    const reader = port.readable.getReader();
    log(`리더기 생성 완료: ${reader}`);

    let RecvComBuffer: Array<number> = [];
    let RS232_RXStep = 0;

    const processValue = (tmp: number) => {
      if (RS232_RXStep === 0) {
        if (tmp !== 0xfe) {
          if (tmp === 0x11) {
            //ACK
            log('ACK');
          } else if (tmp === 0xee) {
            //NAK
            log('NAK');
          } else {
            log(`시리얼 통신 에러:: 알수 없는 패킷 헤더 ${tmp}`);
          }
        } else {
          RecvComBuffer[RS232_RXStep++] = tmp;
        }
      } else if (RS232_RXStep === 1) {
        if (tmp !== 18) {
          RS232_RXStep = 0;
        } else {
          RecvComBuffer[RS232_RXStep++] = tmp;
        }
      } else {
        RecvComBuffer[RS232_RXStep++] = tmp;
        if (RS232_RXStep === 19) {
          RS232_RXStep = 0;
          console.log('RecvComBuffer::', RecvComBuffer);
          const chksum = calculateFCC(RecvComBuffer.slice(0, -1));
          if (
            chksum === RecvComBuffer[18] &&
            (RecvComBuffer[17] === 0x13 || RecvComBuffer[17] === 0x15)
          ) {
            // 들어온 FCC와 체크섬 비교 & 버전 체크
            const CMD = RecvComBuffer[2];
            switch (CMD) {
              case 0x13: {
                // 상태 조회 응답
                if (timerRef.current) {
                  clearTimeout(timerRef.current);
                }
                const [statusArr1, statusArr2, ...countArr] =
                  RecvComBuffer.slice(3, -1).map((v, i) => {
                    if (i < 2) {
                      return v
                        .toString(2)
                        .padStart(8, '0')
                        .split('')
                        .map(Number);
                    } else {
                      return +v;
                    }
                  });

                const isEmitting = (statusArr1 as number[])[4];
                if (isEmitting) {
                  return;
                }

                //* 방출 중이면 아래 로직 실행X
                log(`응답 수신(0x13 기본 조회) : ${RecvComBuffer}`);
                const hp1Error = Boolean((statusArr1 as number[])[1]);
                const bd1Error = Boolean((statusArr2 as number[])[7]);
                const bd2Error = Boolean((statusArr2 as number[])[6]);
                const bd3Error = Boolean((statusArr2 as number[])[5]);

                /* 리셋(0x10) 요청시 자동 기본 조회(0x13) 응답 처리*/
                //- 리셋했을 때 에러 없으면: 자동 기본 조회 응답 1회만 옴
                //- 리셋했을 때 여전히 에러있으면: 자동 기본 조회 응답 2회 옴
                if (currentCMDRef.current === 'RESET') {
                  resetDefaultInquiryCountRef.current++;
                  if (resetDefaultInquiryCountRef.current === 1) {
                    if (hp1Error || bd1Error || bd2Error || bd3Error) {
                      //리셋시 에러 있으면 2회 기본 조회할 경우,
                      //첫번째 조회 응답은 부정확하게 와서 break로 나가기(2번째때 처리)
                      log(
                        '리셋시 에러 존재(2회 기본 조회 응답 중 1회) - break',
                      );
                      break;
                    } else {
                      //리셋시 에러 없어서 기본 조회 1회만 하고 끝남. 밑에서 상태 업데이트
                      resetDefaultInquiryCountRef.current = 0;
                      log(
                        '리셋시 에러 없음(1회 기본 조회 응답 중 1회) - 응답 처리',
                      );
                    }
                  } else {
                    //기본 조회 2회 진행 중
                    resetDefaultInquiryCountRef.current = 0;
                    log(
                      '리셋시 에러 존재(2회 기본 조회 응답 중 2회) - 응답 처리',
                    );
                  }
                }
                /* 방출(0x12) 요청시 자동 기본 조회(0x13) 응답 처리*/
                // - 방출 성공시: 자동 기본 조회 응답 1회만 옴
                // - 방출 실패시: 자동 기본 조회 응답 2회옴
                if (currentCMDRef.current === 'EMIT') {
                  emitDefaultInquiryCountRef.current++;
                  log('방출(기본 조회 응답 1회) - 응답 처리');
                  if (emitDefaultInquiryCountRef.current === 2) {
                    //성공 방출 두번째 자동 기본 조회시 더 차감해서 업데이트 하지 않도록 break로 나가기
                    log(
                      '방출시 에러 없음(2회 기본 조회 응답 중 2회) - 초기화 + break',
                    );
                    emitDefaultInquiryCountRef.current = 0;
                    currentCMDRef.current = null;
                    break;
                  }
                }

                //잔돈 수량 및 키오스트 상태 서버에 저장
                const hp1Count = countArr[6] as number;
                const bd1Count = countArr[8] as number;
                const bd2Count = countArr[9] as number;
                const bd3Count = countArr[10] as number;

                const newKioskStatus =
                  currentCMDRef.current === 'EMIT'
                    ? //* 방출일 경우: 잔돈 차감한 값, 에러 여부 업데이트
                      {
                        ...currentKioskStatusRef.current,
                        hp1TotalCount:
                          currentKioskStatusRef.current.hp1TotalCount -
                          hp1Count,
                        bd1TotalCount:
                          currentKioskStatusRef.current.bd1TotalCount -
                          bd1Count,
                        bd2TotalCount:
                          currentKioskStatusRef.current.bd2TotalCount -
                          bd2Count,
                        bd3TotalCount:
                          currentKioskStatusRef.current.bd3TotalCount -
                          bd3Count,
                        hp1Error,
                        bd1Error,
                        bd2Error,
                        bd3Error,
                      }
                    : {
                        //* 리셋일 경우: 에러 여부와 에러 여부가 0이면 에러 코드 0으로 업데이트
                        ...currentKioskStatusRef.current,
                        hp1Error,
                        bd1Error,
                        bd2Error,
                        bd3Error,
                        bd1ErrorCode: bd1Error
                          ? currentKioskStatusRef.current.bd1ErrorCode
                          : 0,
                        bd2ErrorCode: bd2Error
                          ? currentKioskStatusRef.current.bd2ErrorCode
                          : 0,
                        bd3ErrorCode: bd3Error
                          ? currentKioskStatusRef.current.bd3ErrorCode
                          : 0,
                      };
                setKioskStatus(newKioskStatus);
                currentKioskStatusRef.current = newKioskStatus;
                mutate({
                  type: 'WITHDRAWAL',
                  controlCode: currentLoginInfoRef.current.controlCode,
                  ...newKioskStatus,
                });
                if (currentCMDRef.current === 'EMIT') {
                  //실제 방출 수량 비교후 덜뽑힌 금액 전역 상태로 저장
                  const newMoneyWithDrawn = {
                    hp1Count:
                      withdrawalTargetCountRef.current.hp1Count - hp1Count,
                    bd1Count:
                      withdrawalTargetCountRef.current.bd1Count - bd1Count,
                    bd2Count:
                      withdrawalTargetCountRef.current.bd2Count - bd2Count,
                    bd3Count:
                      withdrawalTargetCountRef.current.bd3Count - bd3Count,
                  };
                  setMoneyNotWithdrawn(newMoneyWithDrawn);
                  currentMoneyNotWithdrawnRef.current = newMoneyWithDrawn;
                  log(`미방출 수량: ${newMoneyWithDrawn}`);
                }

                //에러났으면,
                if (hp1Error || bd1Error || bd2Error || bd3Error) {
                  //상세 에러코드 조회
                  fetchKioskStatus('ERROR');
                } else {
                  //에러 안났으면 다음 스텝 진행
                  if (currentCMDRef.current === 'EMIT') {
                    setIsLoading(false);
                    setAdminLoginInfo({
                      isManager: null,
                      controlCode: null,
                    });
                    log('환급액 방출 성공:: 환급 완료 페이지로 이동');
                    setTimeout(() => {
                      goRefundComplete();
                    }, 500);
                  } else if (currentCMDRef.current === 'RESET') {
                    //미방출 금액 있는지 확인해서 그에 맞는 모달 생성
                    currentCMDRef.current = null;
                    setIsLoading(false);
                    const { bd1Count, bd2Count, bd3Count, hp1Count } =
                      currentMoneyNotWithdrawnRef.current;

                    if (Boolean(bd1Count || bd2Count || bd3Count || hp1Count)) {
                      //미지급된 환급금이 있는 경우
                      setModalInfo({
                        title: '키오스크 상태가 정상입니다.',
                        description: `확인을 누르시면 미지급 환급금이 출금됩니다.\n출금된 지폐를 고객님께 지급해주세요.`,
                        icon: 'CHECK',
                        btnCallback: () => {
                          log(
                            `리셋 성공:: 미방출 금액 재방출\nbd1Count:${bd1Count}, bd2Count:${bd2Count},bd3Count:${bd3Count}, hp1Count:${hp1Count}`,
                          );
                          emitBills(currentMoneyNotWithdrawnRef.current);
                        },
                      });
                    } else {
                      setModalInfo({
                        title: '키오스크 상태가 정상입니다.',
                        description: '',
                        icon: 'CHECK',
                        btnCallback: () => {
                          log(
                            `리셋 성공:: 미방출 금액 없음. 환급 완료 페이지로 이동`,
                          );
                          setAdminLoginInfo({
                            isManager: null,
                            controlCode: null,
                          });
                          setTimeout(() => {
                            goRefundComplete();
                          }, 500);
                        },
                      });
                    }
                  }
                }
                break;
              }
              case 0x19: {
                // 방출기 에러코드 응답
                emitDefaultInquiryCountRef.current = 0;
                const [
                  statusArr1,
                  statusArr2,
                  bd1ErrorCode,
                  bd2ErrorCode,
                  bd3ErrorCode,
                ] = RecvComBuffer.slice(3, -1).map((v, i) => {
                  if (i < 2) {
                    return v.toString(2).padStart(8, '0').split('').map(Number);
                  } else {
                    return +v;
                  }
                });
                const newKioskStatus = {
                  ...currentKioskStatusRef.current,
                  bd1Error: Boolean((statusArr2 as number[])[7]),
                  bd2Error: Boolean((statusArr2 as number[])[6]),
                  bd3Error: Boolean((statusArr2 as number[])[5]),
                  hp1Error: Boolean((statusArr1 as number[])[1]),
                  bd1ErrorCode,
                  bd2ErrorCode,
                  bd3ErrorCode,
                };
                log(
                  `** 에러 발생 **\n- 응답 수신(0x19 에러 조회): ${RecvComBuffer}\n- 에러 정보: ${JSON.stringify(
                    newKioskStatus,
                  )}`,
                );
                setKioskStatus(newKioskStatus);
                currentKioskStatusRef.current = newKioskStatus;
                mutate({
                  type: 'WITHDRAWAL',
                  controlCode: currentLoginInfoRef.current.controlCode,
                  ...newKioskStatus,
                });
                setIsLoading(false);

                //현재 설정 페이지에 위치해있는지에 따라 에러 모달 다르게 생성
                const isOnSettingPage =
                  currentLocationRef.current === '/setting';
                setModalInfo({
                  title: isOnSettingPage
                    ? '에러가 해결되지 않았습니다.'
                    : `에러가 발생했습니다.${
                        currentKioskStatusRef.current.hp1Error
                          ? ''
                          : `(${
                              convertErrorCode(
                                (bd1ErrorCode as number) ||
                                  (bd2ErrorCode as number) ||
                                  (bd3ErrorCode as number),
                              ).code
                            })`
                      }`,
                  description: isOnSettingPage
                    ? '키오스크 에러 메뉴얼에 따라 조치해주세요.\n메뉴얼은 키오스크 내부에 부착되어 있습니다.'
                    : t('error_kiosk_desc'),
                  btnText: isOnSettingPage ? '닫기' : '조치하기',
                  btnCallback: () => {
                    if (isOnSettingPage) {
                      return;
                    }
                    navigate('/setting');
                  },
                  icon: isOnSettingPage ? 'ALERT' : 'ERROR',
                });
                break;
              }
              default:
                log(`알 수 없는 CMD 에러 : ${CMD}`);
            }
          } else {
            // 수신 오류
            log('시리얼 통신 에러:: 유효하지 않은 형식의 응답 수신');
          }
        }
      }
    };

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          log('리더기 상태: DONE');
          await reader.releaseLock();
          break;
        }
        value.forEach(processValue);
      }
      return 0;
    } catch (err) {
      log(`시리얼 통신 에러:: 응답 수신 에러 ${err}`);
    }
  };

  useEffect(() => {
    if (port) {
      setModalInfo({
        title: '키오스크 연결이 완료되었습니다.',
        description: '',
        icon: 'CHECK',
        btnCallback: () => {
          readResponse();
        },
      });
    } else {
      openInitializationModal();
    }
  }, [port]);

  return {
    resetKiosk,
    readResponse,
    emitBills,
    initialize,
    isLoading: isLoading || refundCompleteLoading,
  };
}

export default useSerialCommunication;
